
import { mapState } from "vuex";
  import info from "@/components/_universal/list-info.vue";
  import tablelist from "@/components/_universal/list-table.vue";

  // Standard Components
  import EVENTS from "@/constants/events";

  // Controller
  import SalesController from "@/controllers/sales-controller";
  import listMixin from "@/mixin/list-mixin";
  import SalesPerformance from "@/views/sales/sales-performance.vue";
  import SalesReport from "@/views/sales/sales-report.vue";

  // Initialize the SalesController instance once so it can be reused.
  const salesController = new SalesController();

  export default {
    mixins: [listMixin],
    data() {
      return {
        listDataObj: {},
        EVENT_ACTION: {},
        project: {},
        timer: null,          // Holds the interval timer ID.
        unsubscribe: null,    // Holds the store subscription function.
        apiCallCount: 0       // Tracks the total number of API calls.
      };
    },
    computed: {
      // Map state from Vuex
      ...mapState({
        infoFilter: (state: any) => state.Application.list.filter,
      }),
      infoData() {
        if (!this.stats.data) return;
        return this.stats.data.filter((i) => !i.isHighlightSection);
      },
      infoHighlightsData() {
        if (!this.stats.data) return;
        return this.stats.data.filter((i) => i.isHighlightSection);
      },
    },
    methods: {
      /**
       * onFilterChange: Called when the filter changes.
       * It fetches new list results and updates the store.
       */
      async onFilterChange() {
        // Get current filter from store
        if (this.$store.getters.getList.filter) {
          this.filter = this.$store.getters.getList.filter;
        }

        // Call API and pass new filter object parameter
        let data = await salesController.getListResults(this.filter);

        // Update store results
        await this.updateResults(data.resources);
      },
      /**
       * loadData: Loads the initial list and sets up the timer to refresh the list.
       * Also increments the API call count and stops after 30 calls.
       */
      async loadData() {
        // Set any additional filter criteria
        this.filter.exclude = "";

        // Load initial list data via the controller
        this.listDataObj = await salesController.list();

        // Update the store if data is successfully retrieved
        if (this.listDataObj.table.data.success) {
          this.updateResults(this.listDataObj.table.data.resources);
        }

        // Count the initial API call (if you want to include it)
        this.apiCallCount++;

        // Set an interval to reload data every 20 seconds
        this.timer = setInterval(async () => {
          // Check if we've reached 30 API calls
          if (this.apiCallCount >= 30) {
            clearInterval(this.timer);
            this.timer = null;
            
            return;
          }
          // Call the API to update the list (e.g., on filter change)
          await this.onFilterChange();
          // Increment the API call count after each call
          this.apiCallCount++;
         
        }, 20000);
      },
      /**
       * updateResults: Dispatches the new list data to the Vuex store.
       */
      async updateResults(results) {
        this.$store.dispatch("setList", results);
      },
      /**
       * subscribeOnFilterChange: Subscribes to Vuex mutations to re-trigger API calls when filters change.
       */
      subscribeOnFilterChange() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
          if (mutation.type === "setFilter") {
            this.onFilterChange();
          }
        });
      },
    },
    components: {
      info,
      tablelist,
      SalesPerformance,
      SalesReport,
    },
    async mounted() {
      // Set event constants locally.
      this.EVENT_ACTION = EVENTS;
      // Set up subscription for filter changes.
      this.subscribeOnFilterChange();
    },
    async created() {
      // Import event constants and assign them.
      this.EVENT_ACTION = EVENTS;
      // Load initial data.
      await this.loadData();
    },
    beforeDestroy() {
      // Clean up the interval timer if it exists.
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      // Unsubscribe from Vuex store events if a subscription exists.
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      // Reset store data.
      this.updateResults(null);
    },
  };
